body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.dark-toast{
  background: #212121;
  color: #fff;
}

.dark-toast > .toastify__close{
  color: #fff;
}

/*Loader*/
.Page-Loader {
  animation: Page-Loader-spin infinite 1.5s linear;
  height: 80px;
}

@keyframes Page-Loader-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}