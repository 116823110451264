/*Import font-awesome*/
@import url(../node_modules/@fortawesome/fontawesome-free/css/all.css);
/*Import Core*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: flex; }

body,
html {
  width: 100%;
  height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

ol,
ul {
  list-style: none; }

hr {
  border: 0;
  height: 1px;
  background: #ccc; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box; }

html,
input,
select,
textarea,
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Black.eot");
  src: url("font/hinted-AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Black.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Black.woff") format("woff"), url("font/hinted-AvenirLTStd-Black.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Heavy.eot");
  src: url("font/hinted-AvenirLTStd-Heavy.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Heavy.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Heavy.woff") format("woff"), url("font/hinted-AvenirLTStd-Heavy.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Book.eot");
  src: url("font/hinted-AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Book.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Book.woff") format("woff"), url("font/hinted-AvenirLTStd-Book.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Roman.eot");
  src: url("font/hinted-AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Roman.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Roman.woff") format("woff"), url("font/hinted-AvenirLTStd-Roman.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Light.eot");
  src: url("font/hinted-AvenirLTStd-Light.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Light.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Light.woff") format("woff"), url("font/hinted-AvenirLTStd-Light.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Light.svg#AvenirLTStd-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Medium.eot");
  src: url("font/hinted-AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Medium.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Medium.woff") format("woff"), url("font/hinted-AvenirLTStd-Medium.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Medium.svg#AvenirLTStd-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

* {
  font-family: "Avenir LT Std", "Helvetica Nueue", sans-serif; }

html {
  font-size: 62.5%; }

body {
  background-color: #FFFFFA; }

.container {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  overflow: scroll; }

.text_left {
  text-align: left; }

.text_center {
  text-align: center; }

.text_right {
  text-align: right; }

.mb-2 {
  margin-bottom: 2rem; }

button[disabled] {
  background-color: #999999;
  cursor: not-allowed; }

.primary_button {
  border-radius: 4px;
  font-size: 1.4rem;
  font-style: normal;
  background-color: #094063;
  color: #FFFFFA;
  margin: 1rem;
  padding: 7px 15px;
  border: 0;
  cursor: pointer; }
  .primary_button a {
    color: #FFFFFA;
    text-decoration: none; }

.red {
  background-color: #ab0404; }

.green {
  background-color: #13940b; }

.dashboard__primary-btn, .dashboard-refer__primary-btn, .dashboard__primary-btn--full-width {
  background-color: #094063;
  color: #FFFFFF;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  border: 0;
  border-radius: 4px;
  margin-right: 1rem;
  width: fit-content;
  cursor: pointer; }
  a.dashboard__primary-btn, a.dashboard-refer__primary-btn, a.dashboard__primary-btn--full-width {
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    display: block; }

.dashboard__primary-btn:disabled, .dashboard-refer__primary-btn:disabled, .dashboard__primary-btn--full-width:disabled {
  background-color: #8FA8B8; }

.dashboard__secondary-btn, .dashboard-refer__secondary-btn, .dashboard__secondary-btn--full-width {
  width: fit-content;
  border: 1.5px solid #094063;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin-right: 1rem;
  color: #094063;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600; }
  a.dashboard__secondary-btn, a.dashboard-refer__secondary-btn, a.dashboard__secondary-btn--full-width {
    text-align: center;
    font-size: 1.6rem;
    text-decoration: none;
    color: #094063;
    display: block;
    text-align: center; }

.dashboard__secondary-btn:hover, .dashboard-refer__secondary-btn:hover, .dashboard__secondary-btn--full-width:hover {
  background-color: #175171;
  color: #FFFFFF; }
  .dashboard__secondary-btn:hover a, .dashboard-refer__secondary-btn:hover a, .dashboard__secondary-btn--full-width:hover a {
    color: #FFFFFF; }

.dashboard__secondary-btn:focus, .dashboard-refer__secondary-btn:focus, .dashboard__secondary-btn--full-width:focus {
  background-color: #093B5A;
  color: #FFFFFF; }

.dashboard-refer__primary-btn {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 300px; }

.dashboard-refer__secondary-btn {
  margin-bottom: 1em;
  width: 300px; }

.dashboard__primary-btn--full-width {
  display: block;
  width: 100%;
  margin-bottom: 25px; }

.dashboard__secondary-btn--full-width {
  display: block;
  width: 100%;
  margin-bottom: 25px; }

.dashboard-table-wrap {
  display: flex;
  flex-direction: column; }

.dashboard-table-overflow {
  overflow-x: scroll; }

.dashboard-table {
  width: 100%; }

.dashboard-table tr:nth-child(odd) {
  background-color: rgba(81, 153, 211, 0.03); }

.dashboard-table tr:hover {
  background-color: #F9FAFC; }

.dashboard-table-item {
  height: 5rem;
  min-height: 5rem;
  font-size: 1.4rem;
  text-align: left;
  padding-left: 2rem;
  color: #212121;
  vertical-align: middle; }

.dashboard-table-item__header {
  background-color: rgba(81, 153, 211, 0.04);
  font-weight: 800; }

.dashboard-table-item__body {
  font-weight: 500;
  color: #333333; }

.new_feedback_form {
  min-width: 30rem;
  padding: 3rem; }

.select_pharamacy_form {
  min-width: 30rem;
  padding: 3rem; }

.button_link {
  background: none;
  border: none;
  color: #094063;
  font-size: 1.4rem;
  text-decoration: underline;
  padding: 0.5rem 0; }

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300; }
  .login_form .form_wrapper {
    padding: 2rem; }
    .login_form .form_wrapper .form {
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom: 8rem; }
      .login_form .form_wrapper .form input[type="text"],
      .login_form .form_wrapper .form input[type="password"] {
        width: 100%;
        height: 6rem;
        padding: 2rem;
        border: 1px solid #c0e5e5;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 2rem;
        margin-bottom: 1rem; }
      .login_form .form_wrapper .form button {
        border-radius: 4px;
        font-size: 1.6rem;
        width: 100%;
        height: 6.5rem;
        font-style: normal;
        font-weight: 500;
        background-color: #094063;
        color: #FFFFFA;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 0;
        cursor: pointer; }

.dashboard-list-item {
  height: 5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e6f0f9;
  font-size: 1.6rem;
  color: #212121;
  font-weight: 600; }
  .dashboard-list-item a {
    font-size: 1.6rem;
    color: #212121;
    text-decoration: none;
    font-weight: 600; }

.dashboard-list-item:hover {
  background-color: #F9FAFC; }

.dashboard-control__button, .article__btn {
  font-weight: 800;
  font-size: 1.6rem;
  width: 14rem; }

.infinite-scroll-btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  align-items: center;
  margin-bottom: 3rem;
  cursor: pointer;
  box-shadow: 0 0 3px 0px #ccc; }

.dash {
  height: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  box-sizing: border-box; }

.column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }

#left {
  flex-shrink: 0; }

.top-left {
  flex-shrink: 0; }

.top-right {
  flex-shrink: 0;
  display: inline-flex; }

.bottom {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.dashboard-content-background {
  background-color: #F8F8F8;
  padding: 2rem; }

.dashboard {
  display: flex;
  flex-direction: row;
  width: 100%; }

.sidebar {
  width: 20%;
  height: 100%;
  background-color: #FFFFFF;
  font-size: 1.6rem;
  min-width: 25rem; }
  .sidebar ul {
    width: 100%; }

.sidebar__top {
  height: 8rem;
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  background-color: #107BC0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.25); }

.sidebar--space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sidebar--enrollee {
  background-color: #107BC0; }

.dashboard__menu {
  padding: 2rem;
  display: flex;
  border-left: 5px solid transparent;
  align-items: center;
  padding-left: 1.5rem;
  text-decoration: none;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 300; }
  .dashboard__menu .fas {
    margin-right: 2rem;
    color: #FFFFFF;
    font-size: 2rem;
    width: 3rem; }

.menu-active {
  border-left: 5px solid #89CBF0;
  background: rgba(9, 64, 99, 0.2); }

.content {
  width: 100%;
  position: relative; }

.header {
  height: 8rem;
  background-color: #FFFFFF;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header__left {
  display: none;
  align-items: center; }
  .header__left .toggle-nav {
    display: none; }

.header__title h2 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  color: #094063; }

.header__right {
  display: flex;
  align-items: center;
  justify-content: center; }
  .header__right button {
    border-radius: 4px;
    font-size: 1.6rem;
    padding: 0 1rem;
    min-width: 15rem;
    height: 4rem;
    font-style: normal;
    font-weight: normal;
    background-color: #094063;
    color: #FFFFFF;
    border: 0;
    margin: 0 1rem; }
  .header__right .header__notif button {
    width: 2rem;
    height: 2rem; }
  .header__right .header__notif .notif {
    border: 0;
    background-color: #FFFFFF;
    color: #094063; }
  .header__right .header__search {
    width: 16rem;
    height: 4rem; }
    .header__right .header__search input {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      border: 1px solid #094063;
      padding: 1rem; }

.badge {
  position: relative; }

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 0.8rem;
  background: #d72c2c;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal; }

.active {
  display: flex;
  flex-direction: column; }

.fa {
  color: #094063; }

.article__title {
  color: #212121;
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin-bottom: 2rem; }

.article__img {
  width: 100%;
  height: 22rem;
  margin: 2rem 0; }
  .article__img img {
    max-width: 100%;
    height: inherit; }

.article__text {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #333;
  text-align: justify; }
  .article__text p {
    float: none; }
  .article__text img {
    max-width: 40%;
    float: left;
    margin-right: 2rem; }

.article__text--bold {
  font-weight: 800; }

.article__btn {
  margin-top: 2rem; }
  .article__btn a {
    color: #FFFFFF;
    font-size: 1.6rem;
    text-decoration: none; }

.tips-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  flex-flow: wrap; }

.tips__time-date-stamp {
  font-size: 1.2rem;
  color: #a5a5a5; }

.tips {
  width: 32%;
  cursor: pointer;
  margin-bottom: 2rem; }

.tips__image {
  width: 100%;
  min-height: 14rem; }

.tips__textarea {
  padding: 1rem; }

.tips__title {
  font-size: 1.6rem;
  margin: 1rem 0;
  color: #212121; }

.tips__preview {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6rem;
  color: #333333;
  margin-bottom: 1rem; }

.tips__textarea--button {
  border-top: 0.5px solid #e5e5e5;
  padding-top: 1rem; }
  .tips__textarea--button button {
    background-color: #FFFFFA;
    font-size: 1.2rem;
    font-weight: normal;
    font-style: normal;
    color: #107BC0;
    padding-right: 1rem;
    border: 0; }
  .tips__textarea--button a {
    color: #107BC0;
    text-decoration: none; }

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid;
  display: flex; }

.sc_active {
  color: green; }

.sc_dead {
  color: #ab0404; }

.signup {
  display: flex;
  flex-direction: column;
  align-items: center; }

.signup__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1);
  width: 100%; }
  .signup__header img {
    height: 4rem;
    margin-bottom: 10px; }
  .signup__header p {
    font-size: 2.2rem;
    color: #212121;
    font-style: normal;
    font-weight: normal; }

.form_group {
  display: inline-flex; }

.form hr {
  margin: 10px 0; }

.form select {
  padding: 2px; }

.form select,
.form option,
.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="phone"],
.form input[type="number"],
.form input[type="tel"] {
  width: 100%;
  height: 4rem;
  padding: 0.5rem;
  border: 1px solid #c0e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.form select {
  padding: 2px; }

.form input[type="checkbox"],
.form input[type="radio"] {
  vertical-align: middle;
  height: 1.9rem;
  width: 1rem;
  margin: 1rem 1rem 1rem 0; }

.form input[type="submit"] {
  background-color: #094063;
  border: none;
  color: white;
  padding: 1rem;
  font-weight: 600;
  width: fit-content;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-right: 1rem; }

.form input[type="button"] {
  background-color: #FFFFFF;
  border: 1.5px solid #094063;
  color: #094063;
  font-weight: 600;
  width: fit-content;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer; }

.form input[type="file"] {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.form .form-group {
  margin: 10px 0; }

.form .hint {
  font-size: 10px;
  color: #aaa;
  font-style: italic; }

.form .radio {
  padding-left: 24px;
  margin: 10px 0; }
  .form .radio input[type="radio"] {
    margin-left: -24px;
    display: inline-block;
    vertical-align: middle; }
  .form .radio label {
    display: inline-block;
    vertical-align: middle; }

.form .input-error {
  color: #ab0404;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 10px; }

.form .input-message {
  margin: 15px 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #212121;
  display: block; }

.planner__form {
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 30px 20px; }
  .planner__form h1 {
    font-size: 32px; }
  .planner__form .intro {
    font-size: 14px;
    margin: 10px 0; }
  .planner__form h1 {
    font-size: 32px; }

.signup__form {
  max-width: 90%;
  width: 40rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  color: #212121; }

.sign__form__file-input {
  display: flex;
  flex-direction: column; }
  .sign__form__file-input button {
    background-color: #fff;
    border: 1.5px solid #094063;
    color: #094063;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0; }

.button-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background: #fffffa;
  width: 80%;
  position: fixed;
  z-index: 5; }

.dashboard-btn-section {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  width: 100%;
  background: #FFFFFF; }

.profile-section {
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-start;
  align-items: flex-start; }

.profile__img {
  height: 24rem;
  width: 24rem;
  border-radius: 4px; }

.profile-detail-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: space-between;
  padding-top: 0; }

.profile-item {
  width: 28rem;
  margin-right: 2rem; }

.profile-item-wrapper, .dashboard-form-item {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  justify-content: space-between;
  line-height: 2.4rem;
  overflow-wrap: break-word; }

.dashboard-card {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(81, 153, 211, 0.04);
  border-radius: 4px;
  color: #094063;
  padding: 2rem;
  cursor: pointer; }

.dashboard-card--pb-large {
  padding-bottom: 10rem; }

.dashboard-card__focus-text {
  font-size: 2.4rem;
  color: #212121;
  margin-bottom: 2rem;
  font-weight: 800; }

.dashboard-card__title-text, .dashboard-card__title-text--light {
  font-size: 1.8rem;
  color: #212121;
  margin-bottom: 2rem;
  font-weight: 800; }

.dashboard-card__title-text--light {
  font-weight: 500; }

.dashboard-card__body-text, .dashboard-card__body-text--bold {
  font-size: 1.6rem;
  color: #212121;
  margin-bottom: 2rem; }

.dashboard-card__body-text--bold {
  font-weight: bolder; }

.dashboard-card__data-text {
  font-size: 2.2rem;
  font-weight: 800; }

.dashboard-card__text--left-align {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.dashboard-card__alert {
  width: 100%;
  padding: 5px; }

.dashboard-card__alert--blue {
  background-color: #E6F0F9;
  color: #333333; }

.unassigned-prod-modal-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0; }

.unassigned-prod-modal-wrap__small-text {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1rem; }

.contact-us, .secondary-care-contact {
  margin-bottom: 2rem;
  background-color: #107BC0;
  color: #FFF; }
  .contact-us h2, .secondary-care-contact h2 {
    font-size: 1.6rem; }
  .contact-us a, .secondary-care-contact a {
    color: #FFF; }

.sc-card-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 20rem;
  margin-bottom: 2rem; }

.sc-card {
  width: 49%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between; }

.sc-card__title-text {
  font-size: 2rem;
  color: #212121; }

.sc-card__text {
  font-size: 1.6rem;
  color: #4F4F4F; }

.profile-item__label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #4F4F4F; }

.profile-item__text, .dashboard-form__input, .dashboard-form__input--full-width {
  font-size: 1.8rem;
  font-weight: 800;
  color: #212121;
  margin-top: 1rem; }

.full_profile {
  display: none;
  border-radius: 4px;
  margin: 2rem;
  justify-content: space-between;
  flex-direction: column; }
  .full_profile img {
    max-width: 28rem;
    width: 100%; }

.dashboard-tab {
  justify-content: flex-start; }

.dashboard-tab__button {
  min-width: 15rem;
  border: 0;
  padding: 1rem;
  font-size: 1.6rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #FFFFFF;
  margin-right: 1rem;
  cursor: pointer; }

.dashboard-tab__button:hover {
  background-color: #175171;
  color: #FFFFFF; }

.dashboard-tab__button-active {
  font-weight: 800;
  background-color: #094063;
  color: #FFFFFF; }

.full_profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem; }

.profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center; }

.profile__planinfo {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  color: #212121; }

.stats-data-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem; }

.stats-data-card {
  width: 32%;
  height: 12rem;
  display: flex;
  justify-content: space-between; }

.stats-data-icon {
  display: flex !important;
  align-items: flex-end;
  color: #107BC0; }

.viewprofile {
  margin-left: 2rem; }
  .viewprofile button {
    background-color: #094063;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px; }
    .viewprofile button a {
      color: #FFFFFA;
      text-decoration: none;
      letter-spacing: 0.15rem; }

.big_num {
  color: #107bc0;
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 500; }

.smalltext {
  font-size: 1.8rem;
  letter-spacing: 0.05rem; }

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  vertical-align: middle; }
  .switch input {
    display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #107BC0; }

input:focus + .slider {
  box-shadow: 0 0 1px #107BC0; }

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px); }

/* Rounded sliders */
.slider.round {
  border-radius: 20px; }

.slider.round:before {
  border-radius: 50%; }

.dash__settings {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  padding: 2rem;
  font-size: 1.4rem;
  color: #212121; }
  .dash__settings span {
    display: flex;
    width: 100%;
    justify-content: space-between; }

.feedback_scroll_btn {
  font-size: 20px;
  padding: 1rem;
  position: absolute;
  right: 50%;
  bottom: 12%;
  cursor: pointer;
  z-index: 10;
  background-color: #e3e3e3;
  border-radius: 100%;
  box-shadow: 0 0 3px 0px #868686; }

.feedback_scroll_btn.show-btn {
  display: block; }

.feedback_scroll_btn.hide-btn {
  display: none; }

.page-info-banner {
  width: 100%;
  padding: 15px;
  color: #FFFFFA;
  min-height: 8rem;
  display: flex;
  align-items: center;
  background-color: #094063; }

.page-info-banner.error {
  background-color: #ab0404; }

.page-info-banner.sender-info {
  display: flex;
  background: #f5f5f5;
  color: #004c70;
  cursor: pointer;
  border-bottom: 1px solid rgba(9, 64, 99, 0.101961);
  align-items: center; }
  .page-info-banner.sender-info img {
    width: 30px;
    height: 30px;
    border-radius: 100%; }
  .page-info-banner.sender-info .info {
    margin-left: 1rem;
    flex-grow: 1; }
    .page-info-banner.sender-info .info .sender-name {
      font-size: 1.5rem; }
    .page-info-banner.sender-info .info .action_prompt {
      font-size: 0.9rem;
      font-style: italic;
      font-weight: lighter; }
  .page-info-banner.sender-info .fa {
    color: inherit; }

.dashboard-form__input, .dashboard-form__input--full-width {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  width: 36rem;
  outline: none;
  font-weight: 600;
  font-size: 1.6rem; }

.dashboard-form__input:focus, .dashboard-form__input--full-width:focus {
  border: 1px solid #5199D3; }

.dashboard-form__input--full-width {
  width: 100%; }

.dashboard-form {
  display: flex;
  flex-direction: column; }
  .dashboard-form input[type="radio"],
  .dashboard-form input[type="checkbox"] {
    margin-right: 1rem;
    font-size: 2rem; }

.dashboard-form-item-wrapper {
  display: flex; }

.dashboard-form__btn {
  margin-top: 4rem;
  font-size: 1.6rem;
  font-weight: 800;
  align-self: end; }

.dashboard-form__checklist-wrap {
  color: #212121;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  width: 36rem;
  flex-direction: column; }

.dashboard-form-item {
  width: 36rem; }

.dashboard-form-item__radio-check-btn {
  display: block;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px; }
  .dashboard-form-item__radio-check-btn label {
    padding: 1rem;
    width: 100%;
    display: block;
    text-align: left;
    color: #212121;
    cursor: pointer;
    position: relative;
    z-index: 2;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 4px; }
    .dashboard-form-item__radio-check-btn label:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: rgba(137, 203, 240, 0.08);
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      z-index: -1; }
    .dashboard-form-item__radio-check-btn label:after {
      width: 24px;
      height: 24px;
      content: "";
      border: 2px solid #094063;
      background-color: #fff;
      background-image: url(../src/img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 68%;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
  .dashboard-form-item__radio-check-btn input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(75, 56, 1);
    opacity: 1; }
  .dashboard-form-item__radio-check-btn input:checked ~ label:after {
    background-color: #094063;
    border-color: #094063; }
  .dashboard-form-item__radio-check-btn input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden; }

.dashboard-form-item__check-btn label:after {
  border-radius: 10%; }

.dashboard-form__select, .referral-dashboard-form__select {
  -webkit-appearance: none;
  background: url(../src/img/down_chevron.svg) no-repeat 95% 50%; }

.dashboard-form__info-text {
  font-size: 1.2rem;
  color: #4f4f4f; }

.filterable-multiselect {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0; }
  .filterable-multiselect .input-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center; }
    .filterable-multiselect .input-wrapper .item {
      background: #ccc;
      border-radius: 0.6rem;
      padding: 0.5rem;
      color: #353434;
      font-size: 1rem;
      text-transform: lowercase;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem; }
      .filterable-multiselect .input-wrapper .item .remove {
        margin-left: 0.7rem;
        font-size: 1rem;
        cursor: pointer;
        color: #353434;
        line-height: 0.8rem; }
    .filterable-multiselect .input-wrapper .input {
      font-size: 1.2rem;
      font-weight: normal;
      border: none;
      box-shadow: none;
      width: auto;
      flex: 1;
      min-width: 2rem;
      margin: 0;
      min-height: 2rem; }
      .filterable-multiselect .input-wrapper .input:focus {
        outline: none; }
  .filterable-multiselect ul.dropdown {
    color: #4f4f4f;
    font-size: 1.2rem;
    margin-top: 0;
    max-height: 21rem;
    overflow-y: scroll;
    border: 1px solid rgba(9, 64, 99, 0.1);
    border-radius: 5px;
    z-index: 9999;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #eeeeee;
    list-style: none;
    padding-left: 0; }
    .filterable-multiselect ul.dropdown .item {
      cursor: pointer;
      border-bottom: 1px solid #eee;
      padding: 0.5rem;
      background-color: #FFFFFF; }
      .filterable-multiselect ul.dropdown .item .text-main {
        font-size: 1.2rem; }
      .filterable-multiselect ul.dropdown .item .text-sub {
        font-size: 0.8rem;
        color: #aaa; }

.convo-thread-wrap {
  border-bottom: 1.5px solid #e6f0f9;
  margin-bottom: 2rem;
  cursor: pointer; }
  .convo-thread-wrap .dashboard-card__title-text, .convo-thread-wrap .dashboard-card__title-text--light {
    margin-bottom: 1rem; }

.convo-thread-wrap__main {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.convo-thread-wrap__message-status {
  font-size: 1.5rem;
  position: relative;
  right: 0.7em;
  border: 3px solid #fff;
  border-radius: 50%; }
  .convo-thread-wrap__message-status.orange {
    color: orange; }
  .convo-thread-wrap__message-status.green {
    color: #13940b; }
  .convo-thread-wrap__message-status.grey {
    color: grey; }

.empty-state-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center; }

.empty-state-btn-wrap {
  width: 30%;
  display: flex;
  justify-content: space-between; }

.chat-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem; }

.chat-doctor-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: sticky;
  top: -20px;
  background-color: #FFFFFF;
  padding: 2rem;
  cursor: pointer; }

.chat-doctor-info {
  display: flex;
  align-items: center; }

.chat-doctor__img {
  border-radius: 50%;
  width: 4rem;
  height: 4rem; }

.chat-doctor-name {
  font-weight: 800;
  color: #212121;
  font-size: 1.6rem; }

.chat-doctor-prompt {
  font-size: 1.2rem;
  color: #333; }

.chat-section {
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2rem 0; }

.chat-item__date {
  display: flex;
  justify-content: center;
  padding: 3rem;
  color: #107BC0;
  font-size: 1.4rem;
  font-weight: 600; }

.enrollee-chat {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
  min-height: fit-content; }

.chat-item__message {
  max-width: 60%;
  padding: 1.5rem;
  height: auto;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 4px; }

.enrollee-chat__time {
  color: #4F4F4F; }

.enrollee-chat__bubble {
  background-color: #E6F0F9;
  color: #212121; }

.doctor-chat {
  display: flex;
  justify-content: flex-start;
  margin: 1rem;
  align-items: flex-end;
  min-height: fit-content; }

.doctor-chat__bubble {
  background-color: #094063;
  color: #FFFFFF;
  margin: 1rem; }

.doctor-chat__title {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #FFFFFF; }

.chat-item__message-time {
  font-size: 1rem;
  margin-top: 2rem; }

.doctor-chat__time {
  color: #F9FAFC; }

.chat-item__message {
  max-width: 65%;
  font-size: 1.4rem;
  line-height: 2rem; }
  .chat-item__message p {
    word-break: break-word; }

.chat-input-wrapper {
  display: flex;
  width: 100%; }

.attachment_count {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 10px; }

.chat-input-details {
  flex-direction: column; }

.chat-input {
  width: 100%;
  display: flex;
  padding: 2rem 0;
  position: sticky;
  bottom: -20px;
  background-color: #FFFFFF;
  border-top: 2px solid; }
  .chat-input input[type="text"] {
    width: 80%;
    border-radius: 4px;
    padding: 2rem;
    background-color: #F8F8F8;
    border: 0;
    font-size: 1.6rem; }
  .chat-input button {
    font-size: 1.6rem;
    padding: 2rem;
    width: 15%;
    border: 0; }

.consultation-tabs {
  background-color: #FFFFFA;
  color: #094063;
  width: 50%;
  padding: 20px;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  border: none;
  cursor: pointer;
  outline: none; }
  .consultation-tabs.activeTab {
    background-color: #094063;
    color: #FFFFFA; }

.chat-item__new-message-count {
  background-color: #094063;
  color: #FFFFFA;
  border-radius: 50%;
  font-weight: bolder;
  min-width: 20px;
  height: 20px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.feedback-chat-input {
  width: 100%;
  display: flex;
  padding: 2rem 0;
  position: sticky;
  bottom: -20px;
  background-color: #FFFFFF;
  border-top: 2px solid; }
  .feedback-chat-input input[type="text"] {
    width: 85%;
    border-radius: 4px;
    padding: 2rem;
    background-color: #F8F8F8;
    border: 0;
    font-size: 1.6rem; }
  .feedback-chat-input button {
    font-size: 1.6rem;
    padding: 2rem;
    width: 15%;
    border: 0; }

.chat-input__file-input {
  align-items: center;
  background-color: #F8F8F8;
  justify-content: center; }
  .chat-input__file-input button {
    border: 0;
    font-size: 1.6rem;
    background-color: #F8F8F8;
    color: #094063; }

.attachment-grid-wrapper {
  max-width: 700px;
  overflow-y: scroll; }
  .attachment-grid-wrapper .attachment-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .attachment-grid-wrapper .attachment-grid .attachment {
      margin: 1rem;
      position: relative;
      width: 200px;
      height: 200px; }
      .attachment-grid-wrapper .attachment-grid .attachment img {
        width: 100%; }
      .attachment-grid-wrapper .attachment-grid .attachment .delete {
        position: absolute;
        background: #11111159;
        color: white;
        padding: 2rem 2rem 2rem 3rem;
        right: 0;
        font-size: 2rem;
        border-bottom-left-radius: 5rem;
        cursor: pointer; }
        .attachment-grid-wrapper .attachment-grid .attachment .delete .fa {
          color: white; }

.planner {
  padding: 1rem;
  color: #212121;
  min-height: 580px; }

.expired-plan-container {
  display: flex;
  background: #F8F8F8;
  justify-content: center;
  flex-direction: column;
  height: 100%; }
  .expired-plan-container .logout-btn-container {
    font-size: 2rem;
    color: white;
    margin: 1rem;
    text-align: right;
    margin-right: 5rem; }
    .expired-plan-container .logout-btn-container .fa {
      color: white; }
    .expired-plan-container .logout-btn-container button {
      font-size: 2rem;
      color: white; }
  .expired-plan-container .content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;
    flex-direction: row;
    margin: auto;
    max-width: 50%;
    margin-top: 2rem;
    overflow-y: scroll; }
    .expired-plan-container .content .content-image {
      max-width: 100%; }
    .expired-plan-container .content .content-main .primary_button {
      margin-left: 0;
      padding: 1rem 3rem; }
  .expired-plan-container .logo {
    max-width: 50px;
    margin: 2rem auto; }
    .expired-plan-container .logo img {
      max-width: 100%; }
  .expired-plan-container .plan-expiry-notice__header-text {
    color: #107BC0;
    font-size: 4.5rem;
    line-height: 5.5rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    margin-bottom: 25px; }
  .expired-plan-container .plan-expiry-notice__body-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #333333;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px; }

.countdown {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #E6F0F9;
  padding: 15px;
  margin-bottom: 4rem;
  border-radius: 4px; }

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); }

.modal.open {
  display: flex; }

.modal-content {
  background-color: #fefefe;
  min-height: 30rem;
  margin: auto;
  padding: 2rem;
  width: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center; }

.modal-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  color: #094063;
  text-transform: capitalize; }

.pharmacy-modal {
  width: auto; }

.modal-close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end; }

.modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  padding: 0 3rem;
  z-index: 9999;
  overflow-y: scroll; }
  .modal-wrapper .modal {
    background: #fff;
    width: 800px;
    height: auto;
    margin: auto;
    border-radius: 3px; }
    .modal-wrapper .modal .modal-header {
      padding: 1.5rem;
      background: #f3f3f3;
      display: flex;
      justify-content: space-between; }
      .modal-wrapper .modal .modal-header .close {
        font-size: 18px;
        margin-right: 2rem;
        align-self: center;
        color: gray;
        font-weight: bolder;
        cursor: pointer; }
    .modal-wrapper .modal .modal-content {
      max-height: 480px; }
    .modal-wrapper .modal .modal-footer {
      padding: 1.5rem;
      border-top: 1px solid #cecece; }
      .modal-wrapper .modal .modal-footer button {
        color: #0a4062;
        font-weight: bolder;
        background: none;
        border: none;
        cursor: pointer; }

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

.footnote {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 1.2rem;
  font-size: 1.1rem;
  background-color: #ededed;
  display: flex;
  justify-content: center; }

.pa-code-modal {
  font-size: 1.3rem;
  text-align: center; }
  .pa-code-modal p {
    margin: 1rem 0; }
  .pa-code-modal .message {
    font-size: 1.7rem; }
  .pa-code-modal .code_holder .code {
    margin: 2rem 0;
    background-color: #f9f9f9;
    padding: 2rem;
    font-weight: bolder;
    border: 1px dashed #ccc;
    border-radius: 0.5rem;
    font-size: 2.5rem; }

.doctor-profile {
  text-align: center; }
  .doctor-profile p {
    font-size: 1.5rem; }
  .doctor-profile .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .doctor-profile .info img {
      border-radius: 100%;
      width: 150px;
      height: 150px;
      margin-bottom: 2rem; }
    .doctor-profile .info .name {
      font-size: 3rem; }
    .doctor-profile .info .smalltext {
      margin-left: 1rem;
      font-size: 1.2rem;
      font-style: italic;
      font-weight: lighter; }
    .doctor-profile .info .profile__infotext {
      padding-bottom: 2rem; }
  .doctor-profile hr {
    margin: 2rem 1rem; }

.doctor-profile.small {
  text-align: left;
  border-bottom: 1px solid #eeeeee;
  padding: 2rem;
  cursor: pointer; }
  .doctor-profile.small img {
    margin-right: 2rem; }
  .doctor-profile.small .info {
    flex-direction: row; }
    .doctor-profile.small .info .profile__label {
      padding-bottom: 0; }
    .doctor-profile.small .info .profile__infotext {
      padding-bottom: 1rem; }
  .doctor-profile.small .smalltext {
    margin-left: 0; }

.alert__banner {
  padding: 2rem 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .alert__banner h3 {
    font-size: 1.7rem;
    margin-bottom: 0.8rem; }
  .alert__banner p {
    font-size: 1.2rem; }
  .alert__banner .dismiss {
    font-weight: bolder;
    cursor: pointer; }
    .alert__banner .dismiss .fa {
      color: white; }

.alert__banner.success {
  background: #009688; }

.alert__banner.error {
  background: #ab0404; }

.calender-event-wrapper {
  padding: 2px 5px;
  background-color: transparent; }
  .calender-event-wrapper .rbc-event {
    background-color: transparent; }
  .calender-event-wrapper .event-item {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold; }
  .calender-event-wrapper .event-item.red {
    background: #ab0404; }
  .calender-event-wrapper .event-item.purple {
    background-color: purple; }
  .calender-event-wrapper .event-item.orange {
    background-color: orange; }
  .calender-event-wrapper .event-item.green {
    background-color: #13940b; }
  .calender-event-wrapper .event-item.white {
    background-color: white; }

@media screen and (max-width: 768px) {
  .dashboard__primary-btn, .dashboard-refer__primary-btn, .dashboard__primary-btn--full-width, .dashboard__secondary-btn, .dashboard-refer__secondary-btn, .dashboard__secondary-btn--full-width {
    font-size: 1.4rem; }
  .dashboard-refer__primary-btn {
    width: 100%; }
  .dashboard-refer__secondary-btn {
    width: 100%; }
  .dashboard-btn-section {
    justify-content: center; }
  .stats-data-card {
    width: 100%; }
  .stats-data-wrap {
    flex-direction: column;
    min-height: 40rem; }
  .dashboard-card__data-text {
    font-size: 1.8rem; }
  .dashboard-card__title-text, .dashboard-card__title-text--light {
    font-size: 1.5rem; }
  .dashboard-list-item {
    font-size: 1.4rem; }
    .dashboard-list-item a {
      font-size: 1.4rem; }
  .dashboard-table-item {
    font-size: 1.4rem;
    padding: 2rem; }
  .tips-wrapper {
    flex-direction: column; }
  .article-wrapper {
    width: 100%; }
  .article__title {
    font-size: 2rem;
    line-height: 3rem; }
  .article__text p {
    float: none; }
  .article__text img {
    max-width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 2rem; }
  .article__img {
    height: 14rem; }
  .dashboard-form-item-wrapper {
    flex-direction: column; }
  .dashboard-input-wrap {
    width: 100%; }
  .profile-input-wrap {
    margin-right: 0; }
  .dashboard-form__btn {
    margin-top: 3rem; }
  .dashboard-form__checklist-wrap {
    width: 100%; }
  .dashboard-form-item {
    width: 100%; }
  .chat-doctor-wrapper {
    flex-wrap: wrap; }
  .chat-input__file-input {
    width: 15%; }
  .chat-input input[type="text"] {
    width: 60%;
    padding: 1rem;
    font-size: 1.4rem; }
  .chat-input button {
    font-size: 1.4rem;
    padding: 1rem;
    width: 25%; }
  .chat-button-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #e6f0f9; }
  .chat-section {
    height: 65vh; }
  .attachment_count {
    font-size: 1.2rem; }
  .sc-card-wrap {
    flex-direction: column;
    min-height: 35rem; }
  .sc-card {
    width: 100%; }
  .contact-us h2, .secondary-care-contact h2 {
    font-size: 1.4rem; }
  .feedback-chat-input input[type="text"] {
    width: 75%;
    padding: 1rem;
    font-size: 1.4rem; }
  .feedback-chat-input button {
    font-size: 1.4rem;
    padding: 1rem;
    width: 25%; }
  .profile-item__text, .dashboard-form__input, .dashboard-form__input--full-width {
    font-size: 1.6rem;
    margin-top: 0.8rem; }
  .active {
    display: none; }
  .sidebar {
    font-size: 1.4rem;
    min-width: 20rem; }
    .sidebar .sidebar__top {
      height: 8rem;
      display: flex;
      padding-left: 2.5rem;
      align-items: center;
      border-bottom: 1px solid rgba(9, 64, 99, 0.1); }
      .sidebar .sidebar__top img {
        min-width: 100%; }
    .sidebar ul {
      width: 100%; }
    .sidebar .dashboard__menu .fa {
      color: #fff;
      font-size: 1.6rem;
      width: 2rem; }
  .header {
    padding: 1rem; }
  .header__left {
    display: flex;
    align-items: center; }
    .header__left .toggle-nav {
      display: flex;
      color: #094063;
      font-size: 2rem;
      border: none;
      margin-right: 1rem;
      background-color: #FFFFFA; }
  .header__title h2 {
    font-size: 1.8rem; }
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header__right button {
      border-radius: 3px;
      font-size: 1.2rem;
      min-width: 10rem;
      height: 3rem;
      font-style: normal;
      font-weight: normal;
      background-color: #094063;
      color: #FFFFFA;
      border: 0;
      margin: 0 1rem; }
    .header__right .header__notif button {
      width: 2rem;
      height: 2rem; }
    .header__right .header__notif .notif {
      border: 0;
      background-color: #FFFFFA;
      color: #094063; }
    .header__right .header__search {
      width: 9rem;
      height: 3rem; }
      .header__right .header__search input {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        border: 1px solid #094063;
        padding: 1rem; }
  .hospital_list th {
    font-size: 1.2rem; }
  .hospital_list td {
    font-size: 1.4rem; }
  .tips {
    width: 100%; }
  .signup__header {
    padding-bottom: 2rem;
    padding-top: 5rem;
    flex-wrap: wrap; }
    .signup__header img {
      height: 2.5rem; }
    .signup__header p {
      font-size: 1.6rem; }
  .profile-section {
    flex-direction: column; }
  .profile-detail-wrap {
    padding: 0; }
  .profile__info {
    padding-top: 4rem; }
  .enrollee_analytic {
    height: 68rem;
    justify-content: space-between;
    flex-direction: column; }
  .analytic_card {
    height: 20rem; }
  .tips-expanded h2 {
    font-size: 2.2rem;
    padding: 1rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: 500; }
  .tips-expanded p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding: 1rem;
    font-style: normal;
    font-weight: 300; }
  .tips-expanded .chat img {
    width: 3.2rem;
    height: 3.2rem; }
  .tips-expanded .doctors_chat .message,
  .tips-expanded .enrollee_chat .message {
    font-size: 1.2rem; }
    .tips-expanded .doctors_chat .message p,
    .tips-expanded .enrollee_chat .message p {
      line-height: 2rem; }
  body .fc {
    font-size: 1.2rem; }
  .expired-plan-container .content {
    flex-direction: column;
    max-width: 80%;
    justify-content: normal; }
    .expired-plan-container .content .content-image {
      display: none; }
  .modal-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll; }
  .attachment-grid-wrapper {
    max-width: 100%; }
    .attachment-grid-wrapper .attachment-grid .attachment {
      width: 100px;
      height: 100px; }
      .attachment-grid-wrapper .attachment-grid .attachment .delete {
        padding: 1rem 1rem 1rem 2rem;
        font-size: 1.5rem;
        border-bottom-left-radius: 3rem; }
  .doctor-profile.small {
    padding: 2rem 0; }
    .doctor-profile.small img {
      width: 100px;
      height: 100px;
      margin-right: 2rem; }
    .doctor-profile.small .info .profile__label {
      padding-bottom: 0; }
    .doctor-profile.small .info .profile__infotext {
      font-size: 1.6rem;
      padding-bottom: 1rem; }
    .doctor-profile.small .smalltext {
      margin-left: 0; }
  .dashboard-control__button, .article__btn {
    font-size: 1.4rem;
    width: auto; } }

.dashboard-refer-banner {
  margin-bottom: 2rem;
  background-color: #fff;
  border: solid 1px #44a3e6;
  padding: 2px; }

.dashboard-refer-banner__button {
  background: none;
  width: 100%;
  text-align: left;
  border: none;
  border-left: solid #44a3e6;
  padding: 1.5rem 1rem; }
  .dashboard-refer-banner__button .fa {
    color: #44a3e6;
    margin-right: 1.2rem; }

.refer-dashboard-section, .refer-dashboard-section--fixed-width {
  padding: 1em 0;
  margin-bottom: 2em; }

.refer-dashboard-section--fixed-width {
  max-width: 300px; }

.refer-dashboard-overview__holder {
  display: flex;
  flex-wrap: wrap; }

.refer-dashboard-phone-numbers {
  margin-bottom: 5em; }

.refer-dashboard-overview-card, .refer-dashboard-overview-card--full-width {
  border: 1px solid #E5E5E5;
  width: 33%;
  margin-right: 3%;
  margin-bottom: 3%; }

.refer-dashboard-overview-card--full-width {
  width: 100%; }

.item__badge {
  background-color: #ab0404;
  color: #FDFEFE;
  padding: 2px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 1rem;
  margin: -15px 5px 0 5px; }

@media screen and (max-width: 320px) {
  .profile img {
    height: auto; }
  .planner {
    min-height: 320px; }
  .refer-dashboard-filter-section {
    flex-direction: column; }
  .referral-search-bar {
    width: 100%;
    margin-bottom: 10px; }
  .referral-dashboard-form__select {
    width: 100%;
    margin-bottom: 10px; } }

@media screen and (max-width: 500px) {
  .chat {
    height: 100%; }
  .profile__img {
    width: 100%;
    height: auto; }
  .refer-dashboard-overview-card, .refer-dashboard-overview-card--full-width {
    width: 100%; } }

.hidden {
  display: none; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.pd-left-sm {
  padding-left: 5px; }

.pd-right-sm {
  padding-right: 5px; }

.pd-top-sm {
  padding-top: 5px; }

.pd-bottom-sm {
  padding-bottom: 5px; }

.pagination__container {
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination__btn {
  background-color: transparent !important;
  border: none;
  border-radius: none;
  margin: 5px; }

.pagination__btn_current {
  background-color: transparent !important;
  border: none;
  border-radius: none;
  margin: 5px;
  border-bottom: 1px solid #212121; }

.refer-dashboard-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.referral-dashboard-form__select {
  border: 1px solid #e5e5e5;
  padding: 1rem 1.7rem;
  color: #212121;
  font-weight: 400;
  background: url("./img/caret.svg") no-repeat 95% 50%;
  margin-right: 0; }

.referral-search-bar {
  background: url("./img/search.svg") no-repeat 5% 50%;
  border-radius: 3px;
  height: 100%;
  border: 1px solid #E5E5E5;
  padding: 1rem;
  padding-left: 3rem;
  color: #4F4F4F; }
